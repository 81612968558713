// Function ISO formats a date in 2006-02-01 format
function ISO(d) {
    let m = "00" + (d.getMonth() + 1)
    m = m.slice(m.length - 2)
    let day = "00" + d.getDate()
    day = day.slice(day.length - 2)
    const y = d.getFullYear();
    return y + "-"
        + m + "-"
        + day;
}

// Function displayTodaysPrayer gets the daily prayer for today and displays it
function displayTodaysPrayer() {
    const futurePrayersInput = document.querySelector("input#futurePrayers");

    if (!futurePrayersInput) {
        return;
    }

    const futurePrayers = JSON.parse(futurePrayersInput.value);
    const prayersPage = document.querySelector("input#prayersPage").value;

    const d = new Date();

    const day = d.getDate();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const m = months[d.getMonth()].toString();
    const y = d.getFullYear();

    const p = document.getElementById("prayer");

    //Write date
    document.getElementById("prayerdate").innerHTML = days[d.getDay()].toString() + " " + day + " " + m + " " + y;

    //Prayer
    const dateISO = ISO(d)

    const prayer = futurePrayers.filter(function (page) { return page.date === dateISO })[0]

    if (!prayer) {
        const sec = document.querySelector("#daily-prayer-section");
        if (sec) {
            sec.parentNode.removeChild(sec);
        } else {
            document.querySelector("#noDailyPrayerAvailable").classList.remove("d-none")
        }
    } else {
        for (const paragraph of prayer.content.split("\n\n")) {
            const para = document.createElement("p");
            para.innerText = paragraph;
            p.appendChild(para);
        }
        const todayPermalink = document.getElementById("todayPermalink");
        todayPermalink.classList.remove("d-none");
        todayPermalink.href = prayersPage + dateISO.slice(0, 7) + "/#" + dateISO;
    }

}

displayTodaysPrayer()